import React, {useEffect, useState} from "react";
import {Container} from "aurelia-framework";
import {Client} from "../../api/client";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import {sum} from "../../money/money-tools";
import {clone, debounce} from 'lodash';
import {useDebounce} from "../../utilities/debounce";
import {FlashService} from "../../flash/flash-service";
import {Button, Loader, Modal, Popover, Whisper} from "rsuite";
import ValueFormatter from "../../statistics/service/value-formatter";
import update from "immutability-helper";
import {EventAggregator} from "aurelia-event-aggregator";
import {Chart} from "../../statistics/time-aggregation/chart";

const LoadingValue = ({value, loading, netValue}) => {

    const speaker = netValue ? <Popover>
        <table className="table table-super-condensed calculation-overlay">
            <tbody>
            <tr>
                <th>Brutto:</th>
                <td>{value}</td>
                <th>Netto:</th>
                <td>{netValue}</td>
            </tr>
            </tbody>
        </table>
    </Popover> : <div/>;

    return <>
        {loading ? <Loader/> : <Whisper trigger="hover" placement="auto" speaker={speaker}>
            <span>{value}</span>
        </Whisper>}
    </>
}

export const Calculation = (props) => {
    const {order} = props;

    const client = Container.instance.get(Client);
    const currencyValueConverter = Container.instance.get(CurrencyValueConverter);
    const flashService = Container.instance.get(FlashService);
    const ea = Container.instance.get(EventAggregator);

    console.log('ABC', order.calculationEntries);

    const defaultColumns = order.calculationEntries ?? [];
    let defaults = [20, 25, 30];

    if (order.b2bParticipants && defaults.indexOf(order.b2bParticipants) == -1) {
        defaults.push(order.b2bParticipants);
    }

    for (const defaultValue of defaults) {
        let found = false;

        for (let defaultColumn of defaultColumns) {
            if (defaultColumn.column === defaultValue) {
                found = true;
                break;
            }
        }

        if (!found) {
            defaultColumns.push({column: defaultValue});
        }
    }

    defaultColumns.sort((a, b) => {
        return a.column - b.column;
    });

    const [activeColumn, setActiveColumn] = useState(order.b2bParticipants ?? defaultColumns[0].column);
    const [columns, setColumns] = useState(defaultColumns);
    const [date, setDate] = useState(order.fromDate);

    const [loading, setLoading] = useState(false);
    const [modal, setModalOpen] = useState(false);

    const [data, setData] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const [newColumn, setNewColumn] = useState(null);

    const debouncedDate = useDebounce(date);

    const fetchItems = async (columns) => {

        setLoading(true);

        try {
            let data = (await client.post(
                "order-calculation/query/" + order.id,
                {
                    columns: columns,
                    date: date
                }
            )).data;

            setData(data);
            setColumns(data.columns);

        } catch (e) {

            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');

        } finally {
            setLoading(false);
        }
    };

    const fetchGraph = () => {

        setGraphData(null);
        setLoading(true);

        (client.post(
            "order-calculation/graph/" + order.id,
            {
                columns: columns,
                date: date
            }
        )).then((result) => {

            setGraphData(result.data);
            setModalOpen(true);
            setLoading(false);

        }).catch(e => {
            flashService.error(e.data.message ?? 'Graph konnte nicht geladen werden');
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchItems(columns);

    }, [order.id, debouncedDate, updateTrigger]);

    const getGroupedRows = (rows) => {
        let groupedRows = {};

        for (let row of rows) {

            if (!groupedRows[row.group]) {
                groupedRows[row.group] = [];
            }

            groupedRows[row.group].push(row);
        }

        return groupedRows;
    }

    const patchOrder = async () => {
        const url = `order-calculation/update-margins/${order.id}`;

        const payload = {
            columns: columns,
            activeColumn: activeColumn,
            data: data
        };

        try {
            await client.patch(url, payload);

            ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});

            flashService.success('Erfolgreich gespeichert.');
        } catch (e) {
            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');
        }
    };

    const sumForRows = (rows, index, key) => {

        const moneys = [];

        for (let row of rows) {

            if (row.optional || row.singleMode === 'onlySingle') {
                continue;
            }

            if (row?.[key]?.[index]) {
                moneys.push(row[key][index]);
            } else {
                return '-'
            }
        }

        return currencyValueConverter.toView(sum(moneys));
    }

    const addColumn = () => {
        if (null == newColumn || '' == newColumn) {
            return;
        }

        let col = clone(columns);

        col.push({column: newColumn});

        col.sort((a, b) => {
            return a.column - b.column;
        });

        setColumns(col);
        setNewColumn('');
        debouncedUpdateTrigger();
    }

    const removeColumn = (index) => {
        setColumns(update(columns, {$splice: [[index, 1]]}));
        debouncedUpdateTrigger();
    };

    const setShowInOffer = (index, showInOffer) => {
        let cols = clone(columns);
        cols[index].showInOffer = showInOffer;
        setColumns(cols);
    }

    const debouncedUpdateTrigger = debounce(() => {
        setUpdateTrigger(updateTrigger + 1);
    }, 1000);

    const updateMargin = (index, inland, newValue) => {

        let cols = clone(columns);
        cols[index].total = null;
        cols[index].totalSingle = null;

        if (inland) {
            cols[index].inland = newValue;
            cols[index].inlandSingle = newValue;
        } else {
            cols[index].other = newValue;
            cols[index].otherSingle = newValue;
        }

        setColumns(cols);
        debouncedUpdateTrigger();
    };

    const updatePrice = (index, newValue) => {

        let cols = clone(columns);
        cols[index].total = newValue;
        cols[index].inland = null;
        cols[index].other = null;

        setColumns(cols);
        debouncedUpdateTrigger();
    };

    const updateSinglePrice = (index, newValue) => {

        let cols = clone(columns);
        cols[index].totalSingle = newValue;
        cols[index].inlandSingle = null;
        cols[index].otherSingle = null;

        setColumns(cols);
        debouncedUpdateTrigger();
    };

    console.log('data', data);

    const groupedRows = getGroupedRows(data?.rows ?? []);

    console.log('data', groupedRows);

    return (
        <>
            <Modal size="large" show={modal} onHide={() => {
                setModalOpen(false);
            }}>
                <Modal.Header>
                    <Modal.Title>Graph</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Chart config={{
                        padding: {
                            right: 30
                        },
                        data: {
                            x: 'x',
                            columns: graphData,
                        },
                        axis: {
                            x: {
                                type: 'timeseries',
                                tick: {format: "%d.%m.%Y"}
                            },
                            y: {
                                tick: {
                                    format: value => ValueFormatter.format('money', value)
                                }
                            }
                        },
                        title: {
                            text: ''
                        },
                        tooltip: {
                            tick: {
                                format: value => ValueFormatter.format('money', value)
                            }
                        }
                    }}/>
                </Modal.Body>
            </Modal>
            <div className="panel">
                <div className="panel-body">
                    <table className="table table-condensed table-bordered table-hover">

                        <thead>
                        <tr>
                            <th>
                                Abweichender Starttermin
                            </th>
                            <th colSpan={columns.length}>

                                <input className="form-control"
                                       type="date"
                                       value={date}
                                       onChange={(event) => {
                                           setDate(event.target.value);
                                       }}
                                />

                            </th>
                            <th>
                                <Button onClick={fetchGraph}>Graph</Button>
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Leistung
                            </th>
                            {columns.map((column, index) => <th>
                                {column.column}
                                    <button onClick={() => {
                                        removeColumn(index);
                                    }} className="btn btn-sm btn-danger pull-right" type="button">
                                        -
                                    </button>
                            </th>)}
                            <th style={{width: '150px'}}>

                                <div className="input-group">
                                    <input type="text" className="form-control" value={newColumn} onChange={(event) => {
                                        setNewColumn(event.target.value)
                                    }}/>
                                    <span className="input-group-btn">
                                        <button onClick={() => {
                                            addColumn()
                                        }} className="btn btn-success btn-sm" type="button">
                                            +
                                        </button>
                                    </span>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {Object.keys(groupedRows).map(group => {

                            const rows = groupedRows[group];

                            return <tbody>
                            <tr className="active">
                                <th>{group}</th>
                                {columns.map((column, index) => {
                                    return <th><LoadingValue value={sumForRows(rows, index, 'purchase')}
                                                             netValue={sumForRows(rows, index, 'net')}
                                                             loading={loading}></LoadingValue></th>
                                })}
                                <th/>
                            </tr>
                            {rows.map(row => {

                                if (row.optional || row.singleMode === 'onlySingle') {
                                    return null;
                                }

                                return <tr>
                                    <td>{row.title}</td>
                                    {columns.map((column, index) => {
                                        return <td>
                                            <LoadingValue
                                                value={row?.purchase?.[index] ? currencyValueConverter.toView(row.purchase[index]) : '-'}
                                                netValue={row?.net?.[index] ? currencyValueConverter.toView(row.net[index]) : '-'}
                                                loading={loading}></LoadingValue>
                                        </td>
                                    })}
                                    <td/>
                                </tr>;
                            })}
                            </tbody>

                        })}

                        <tfoot>
                        <tr className="active">
                            <th>Gesamt EK brutto</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue value={sumForRows(data?.rows ?? [], index, 'purchase')}
                                                  netValue={sumForRows(data?.rows ?? [], index, 'net')}
                                                  loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Inland</th>
                            {columns.map((column, index) => {
                                return <th/>;
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <td>Aufschlag %</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.inland ? column.inland * 100 : 0)}
                                           onChange={(event) => {
                                               updateMargin(index, true, event.target.value / 100);
                                           }}/>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P €</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column.inlandCharge ? currencyValueConverter.toView(column.inlandCharge) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr className="active">
                            <th>Drittland</th>
                            {columns.map((column, index) => {
                                return <th/>;
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <td>Aufschlag %</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.other ? column.other * 100 : '')}
                                           onChange={(event) => {
                                               updateMargin(index, false, event.target.value / 100);
                                           }}/>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr>
                            <td>Aufschlag p.P €</td>
                            {columns.map((column, index) => {
                                return <td>
                                    <LoadingValue
                                        value={column?.otherCharge ? currencyValueConverter.toView(column?.otherCharge) : '-'}
                                        loading={loading}></LoadingValue>
                                </td>
                            })}
                            <td/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im DZ brutto</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.total ? column.total / 100 : '')}
                                           onChange={(event) => {
                                               updatePrice(index, event.target.value * 100);
                                           }}/>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im DZ netto</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue
                                        value={column?.netTotal ? currencyValueConverter.toView({
                                            amount: column.netTotal,
                                            currency: 'EUR'
                                        }) : '-'}
                                        loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im EZ brutto</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <input type="number"
                                           className="form-control"
                                           value={(column.totalSingle ? column.totalSingle / 100 : '')}
                                           onChange={(event) => {
                                               updateSinglePrice(index, event.target.value * 100);
                                           }}/>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr className="active">
                            <th>Arrangement Preis im EZ netto</th>
                            {columns.map((column, index) => {
                                return <th>
                                    <LoadingValue
                                        value={column?.netTotalSingle ? currencyValueConverter.toView({
                                            amount: column.netTotalSingle,
                                            currency: 'EUR'
                                        }) : '-'}
                                        loading={loading}></LoadingValue>
                                </th>
                            })}
                            <th/>
                        </tr>
                        <tr>
                            <th>Kalkulationsbasis</th>
                            {columns.map((column, index) => {
                                return <td>
                                    <label>
                                        <input type="radio"
                                               checked={activeColumn == column.column}
                                               onChange={() => {
                                                   setActiveColumn(column.column)
                                               }}
                                        />
                                    </label>
                                </td>
                            })}
                            <td></td>
                        </tr>
                        <tr>
                            <th>Im Angebot anzeigen</th>
                            {columns.map((column, index) => {
                                return <td>
                                    <label>
                                        <input type="checkbox"
                                               checked={column.showInOffer}
                                               onChange={(event) => {
                                                   setShowInOffer(index, event.target.checked);
                                               }}
                                        />
                                    </label>
                                </td>
                            })}
                            <td></td>
                        </tr>
                        </tfoot>

                    </table>

                    <button onClick={() => {
                        patchOrder()
                    }} className="btn btn-primary pull-right" style={{marginTop: '10px'}} type="button">
                        Speichern
                    </button>
                </div>
            </div>
        </>
    );
};

export default Calculation;
